.contact-common-container {
  margin: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000017;
  position: relative;
  overflow: hidden;

  .overlay-container {
    position: absolute;
    z-index: 2;

    width: 100%;
    height: 100%;

    background: linear-gradient(
      180deg,
      rgba(0, 0, 23, 0.28) 0%,
      rgba(0, 0, 23, 0.28) 100%
    );
  }

  .parallax-container {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    z-index: 4;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .home-image-one {
    position: absolute;
    right: -5%;
    width: 45%;
    z-index: 5;
    animation: float 6s ease-in-out infinite;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .home-image-two {
    position: absolute;
    top: -15%;
    right: -2%;
    width: 60%;
    z-index: 3;
    animation: float 6s ease-in-out infinite;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .contact-social-media {
    position: absolute;
    z-index: 10;

    top: 70%;
    left: 16%;

    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;

    background: rgba(0, 0, 63, 0.33);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10.1px);
    -webkit-backdrop-filter: blur(10.1px);

    p {
      font-family: "Roboto", sans-serif;
      font-size: 1rem;
      margin-bottom: 10px;
      color: #fff;
    }

    span {
      font-family: "Roboto", sans-serif;
      font-size: 1rem;
      color: #fff;
    }

    .social-media {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: space-around;
    }
  }

  .map-container {
    position: absolute;
    z-index: 10;
    top: 10%;
    left: 10%;

    width: 30%;
    height: 50%;

    display: flex;
    flex-direction: column;

    h3 {
      color: white;
      display: flex;
      font-size: 1.8rem;
      font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
        "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    }

    .google-map {
      width: 100%;
      height: 100%;

      iframe {
        width: 100%;
        height: 100%;

        border: 0;
      }
    }
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

@media screen and(max-width: 1440px) and (min-width: 1024px) {
  .contact-common-container {
    height: 900px;
    .home-image-one {
      position: absolute;
      top: 10%;
      right: -5%;
      width: 45%;
      z-index: 5;
      animation: float 6s ease-in-out infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .home-image-two {
      position: absolute;
      top: 0;
      right: -2%;
      width: 60%;
      z-index: 3;
      animation: float 6s ease-in-out infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .contact-social-media {
      position: absolute;
      z-index: 10;

      top: 70%;
      left: 18%;

      width: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;

      background: rgba(0, 0, 63, 0.33);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(10.1px);
      -webkit-backdrop-filter: blur(10.1px);

      p {
        font-family: "Roboto", sans-serif;
        font-size: 1rem;
        margin-bottom: 10px;
        color: #fff;
      }

      span {
        font-family: "Roboto", sans-serif;
        font-size: 1rem;
        color: #fff;
      }

      .social-media {
        margin-top: 20px;
        width: 100%;
        display: flex;
        justify-content: space-around;
      }
    }

    .map-container {
      position: absolute;
      z-index: 10;
      top: 10%;
      left: 10%;

      width: 40%;
      height: 50%;

      display: flex;
      flex-direction: column;

      h3 {
        color: white;
        display: flex;
        font-size: 1.8rem;
        font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
          "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
      }

      .google-map {
        width: 100%;
        height: 100%;

        iframe {
          width: 100%;
          height: 100%;

          border: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .contact-common-container {
    height: 900px;
    .home-image-one {
      position: absolute;
      top: 15%;
      right: -5%;
      width: 45%;
      z-index: 5;
      animation: float 6s ease-in-out infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .home-image-two {
      position: absolute;
      top: 5%;
      right: -2%;
      width: 60%;
      z-index: 3;
      animation: float 6s ease-in-out infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .contact-social-media {
      position: absolute;
      z-index: 10;

      top: 70%;
      left: 13%;

      width: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;

      background: rgba(0, 0, 63, 0.33);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(10.1px);
      -webkit-backdrop-filter: blur(10.1px);

      p {
        font-family: "Roboto", sans-serif;
        font-size: 1rem;
        margin-bottom: 10px;
        color: #fff;
      }

      span {
        font-family: "Roboto", sans-serif;
        font-size: 1rem;
        color: #fff;
      }

      .social-media {
        margin-top: 20px;
        width: 100%;
        display: flex;
        justify-content: space-around;
      }
    }

    .map-container {
      position: absolute;
      z-index: 10;
      top: 10%;
      left: 10%;

      width: 40%;
      height: 50%;

      display: flex;
      flex-direction: column;

      h3 {
        color: white;
        display: flex;
        font-size: 1.8rem;
        font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
          "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
      }

      .google-map {
        width: 100%;
        height: 100%;

        iframe {
          width: 100%;
          height: 100%;

          border: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) and (min-width: 425px) {
  .contact-common-container {
    height: 894px;
    .home-image-one {
      position: absolute;

      top: 0;

      left: 0;
      right: -15%;
      margin-left: auto;
      margin-right: auto;

      width: 40%;
      z-index: 5;
      animation: float 6s ease-in-out infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .home-image-two {
      position: absolute;

      top: -4%;

      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 50%;
      z-index: 3;
      animation: float 6s ease-in-out infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .map-container {
      position: absolute;
      z-index: 10;

      top: 27%;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;

      width: 60%;
      height: 40%;

      display: flex;
      flex-direction: column;

      h3 {
        color: white;
        display: flex;
        font-size: 1.2rem;
        font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
          "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
      }

      .google-map {
        width: 100%;
        height: 100%;

        iframe {
          width: 100%;
          height: 100%;

          border: 0;
        }
      }
    }

    .contact-social-media {
      position: absolute;
      z-index: 10;

      top: 660px;

      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;

      width: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;

      background: rgba(0, 0, 63, 0.33);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(10.1px);
      -webkit-backdrop-filter: blur(10.1px);

      p {
        font-family: "Roboto", sans-serif;
        font-size: 1rem;
        margin-bottom: 10px;
        color: #fff;
      }

      span {
        font-family: "Roboto", sans-serif;
        font-size: 1rem;
        color: #fff;
      }

      .social-media {
        margin-top: 20px;
        width: 100%;
        display: flex;
        justify-content: space-around;
      }
    }
  }
}

@media screen and (max-width: 425px) and (min-width: 320px) {
  .contact-common-container {
    height: 894px;
    .home-image-one {
      position: absolute;
      top: 0;

      left: 20%;
      right: 0;
      margin-left: auto;
      margin-right: auto;

      width: 50%;
      z-index: 5;
      animation: float 6s ease-in-out infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .home-image-two {
      position: absolute;

      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;

      top: -5%;
      width: 80%;
      z-index: 3;
      animation: float 6s ease-in-out infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .map-container {
      position: absolute;
      z-index: 10;
      top: 26%;

      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;

      width: 80%;
      height: 40%;

      display: flex;
      flex-direction: column;

      h3 {
        color: white;
        display: flex;
        font-size: 1.2rem;
        font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
          "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
      }

      .google-map {
        width: 100%;
        height: 100%;

        iframe {
          width: 100%;
          height: 100%;

          border: 0;
        }
      }
    }

    .contact-social-media {
      position: absolute;
      z-index: 10;

      bottom: 5%;

      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;

      width: 240px;
      height: 120px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;

      background: rgba(0, 0, 63, 0.33);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(10.1px);
      -webkit-backdrop-filter: blur(10.1px);

      p {
        font-family: "Roboto", sans-serif;
        font-size: 1rem;
        margin-bottom: 10px;
        color: #fff;
      }

      span {
        font-family: "Roboto", sans-serif;
        font-size: 1rem;
        color: #fff;
      }

      .social-media {
        margin-top: 20px;
        width: 100%;
        display: flex;
        justify-content: space-around;
      }
    }
  }
}
