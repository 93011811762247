@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.about-common-container {
  margin: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000017;
  position: relative;
  overflow: hidden;

  .overlay-container {
    position: absolute;
    z-index: 2;

    width: 100%;
    height: 100%;

    background: linear-gradient(
      180deg,
      rgba(0, 0, 23, 0.28) 0%,
      rgba(0, 0, 23, 0.28) 100%
    );
  }

  .parallax-container {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    z-index: 4;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .home-image {
    position: absolute;
    //top: 0;
    right: 0;
    width: 45%;
    z-index: 5;
    animation: float 6s ease-in-out infinite;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .about-common-content {
    z-index: 10;
    position: absolute;
    left: 5%;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 50px;
    margin: 0;

    p {
      color: white;
      font-family: "Roboto", sans-serif;
      margin-top: 20px;
      font-size: 1.4rem;
      font-weight: 400;
      letter-spacing: 0.025em;
      line-height: 2rem;
    }
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

@media screen and (max-width: 1440px) and (min-width: 1024px) {
  .about-common-container {
    height: 900px;
    .about-common-content {
      z-index: 10;
      position: absolute;
      left: 5%;
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 30px 50px;
      margin: 0;
      p {
        color: white;
        font-family: "Roboto", sans-serif;
        margin-top: 20px;
        font-size: 1.4rem;
        font-weight: 500;
        letter-spacing: 0.025em;
        line-height: 2rem;
      }
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .about-common-container {
    height: 900px;
    .about-common-content {
      width: 50%;
      padding: 0;
      margin: 0;
      p {
        color: white;
        font-family: "Roboto", sans-serif;
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: 0.025em;
        line-height: 1.75rem;
      }
    }
  }
}

@media screen and (max-width: 768px) and (min-width: 425px) {
  .about-common-container {
    height: 894px;
    .home-image {
      position: absolute;

      width: 40%;
      z-index: 5;
      animation: float 6s ease-in-out infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .about-common-content {
      z-index: 10;
      position: absolute;

      width: 55%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0;

      p {
        color: white;
        font-family: "Roboto", sans-serif;
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: 0.025em;
        line-height: 1.75rem;
      }
    }

    .parallax-container {
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: -5%;
      z-index: 4;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: 425px) and (min-width: 320px) {
  .about-common-container {
    height: 894px;
    .home-image {
      position: absolute;
      top: 7%;
      left: 25%;
      width: 50%;
      z-index: 5;
      animation: float 6s ease-in-out infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .about-common-content {
      z-index: 10;
      position: absolute;

      top: 30%;

      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0;

      p {
        margin: 10px 5px;
        padding: 0;
        color: white;
        font-family: "Roboto", sans-serif;
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: 0.025em;
        line-height: 1.65rem;
      }
    }

    .parallax-container {
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: -5%;
      z-index: 4;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .about-common-container {
    height: 894px;
    .home-image {
      position: absolute;
      top: 13%;
      left: 20%;
      width: 60%;
      z-index: 5;
      animation: float 6s ease-in-out infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .about-common-content {
      z-index: 10;
      position: absolute;

      bottom: 5%;

      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0;

      p {
        color: white;
        font-family: "Roboto", sans-serif;
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: 0.025em;
        line-height: 1.5rem;
      }
    }

    .parallax-container {
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: -5%;
      z-index: 4;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
