@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

.mobile-nav-container {
  height: 100%;
  display: flex;
  align-items: center;

  .mobile-list {
    position: absolute;
    z-index: 100000;
    margin: 0;
    padding: 0;
    height: 200px;
    display: flex;
    list-style: none;
    background-color: #00003f;
    width: 100%;
    flex-direction: column;
    top: 60px;
    left: 0;

    li {
      width: 100%;
      //position: absolute;
      justify-content: center;
      color: #fff;
      font-weight: 500;
      font-size: 16px;
      display: flex;
      margin-bottom: 15px;
      cursor: pointer;

      a {
        letter-spacing: 0.025rem;
        font-family: "Lato", sans-serif;
        text-decoration: none;
        color: inherit;
        font-size: inherit;
        padding-bottom: 2px;
        padding-left: 3px;
        padding-right: 3px;

        border-bottom: 2px solid transparent;
        transition: all 150ms linear;

        &:hover {
          border-bottom: 2px solid white;
        }

        &:active {
          border-bottom: 2px solid white;
        }
      }
    }
  }
}
