.service-common-container {
  margin: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000017;
  position: relative;
  overflow: hidden;

  .overlay-container {
    position: absolute;
    z-index: 2;

    width: 100%;
    height: 100%;

    background: linear-gradient(
      180deg,
      rgba(0, 0, 23, 0.28) 0%,
      rgba(0, 0, 23, 0.28) 100%
    );
  }

  .parallax-container {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    z-index: 4;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .home-image {
    position: absolute;
    //top: 0;
    right: 5%;
    width: 45%;
    z-index: 5;

    animation: float 6s ease-in-out infinite;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .service-common-content {
    z-index: 10;
    position: absolute;
    left: 5%;
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 50px;
    margin: 0;

    .csr-doc,
    .audio-video-prod,
    .still-motion {
      h3 {
        font-family: "Roboto", sans-serif;
        font-style: italic;
        font-size: 1.6rem;
        margin-bottom: 0.8rem;
        margin-top: 1.2rem;
        color: white;
        letter-spacing: 0.025em;
      }

      p {
        font-family: "Roboto", sans-serif;
        font-size: 1.3rem;
        font-weight: 400;
        margin-top: 0;
        letter-spacing: 0.025em;
        line-height: 1.7rem;
        color: white;
      }
    }
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

@media screen and (max-width: 1440px) and (min-width: 1024px) {
  .service-common-container {
    height: 900px;
    .home-image {
      position: absolute;
      right: 5%;
      width: 40%;
      z-index: 5;

      animation: float 6s ease-in-out infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .service-common-content {
      z-index: 10;
      position: absolute;
      left: 5%;
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 30px 50px;
      margin: 0;

      .csr-doc,
      .audio-video-prod,
      .still-motion {
        h3 {
          font-family: "Roboto", sans-serif;
          font-style: italic;
          font-size: 1.3rem;
          margin-bottom: 0.8rem;
          margin-top: 1.2rem;
          color: white;
          letter-spacing: 0.025em;
        }

        p {
          font-family: "Roboto", sans-serif;
          font-size: 1rem;
          font-weight: 500;
          margin-top: 0;
          letter-spacing: 0.025em;
          line-height: 1.4rem;
          color: white;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .service-common-container {
    height: 900px;
    .home-image {
      position: absolute;
      //top: 0;
      right: 5%;
      width: 30%;
      z-index: 5;

      animation: float 6s ease-in-out infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .service-common-content {
      z-index: 10;
      position: absolute;
      left: 5%;
      width: 55%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 30px 50px;
      margin: 0;

      .csr-doc,
      .audio-video-prod,
      .still-motion {
        h3 {
          font-family: "Roboto", sans-serif;
          font-style: italic;
          font-size: 1.1rem;
          margin-bottom: 0.8rem;
          margin-top: 1.2rem;
          color: white;
          letter-spacing: 0.025em;
        }

        p {
          font-family: "Roboto", sans-serif;
          font-size: 0.9rem;
          font-weight: 500;
          margin-top: 0;
          letter-spacing: 0.025em;
          line-height: 1.4rem;
          color: white;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) and (min-width: 425px) {
  .service-common-container {
    height: 894px;
    .home-image {
      position: absolute;
      //top: 0;
      right: 0;
      width: 40%;
      z-index: 5;

      animation: float 6s ease-in-out infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .service-common-content {
      z-index: 10;
      position: absolute;
      left: 0;
      width: 55%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 30px 50px;
      margin: 0;

      .csr-doc,
      .audio-video-prod,
      .still-motion {
        h3 {
          font-family: "Roboto", sans-serif;
          font-style: italic;
          font-size: 1.1rem;
          margin-bottom: 0.8rem;
          margin-top: 1.2rem;
          color: white;
          letter-spacing: 0.025em;
        }

        p {
          font-family: "Roboto", sans-serif;
          font-size: 0.9rem;
          font-weight: 400;
          margin-top: 0;
          letter-spacing: 0.025em;
          line-height: 1.7rem;
          color: white;
        }
      }
    }
  }
}

@media screen and (max-width: 425px) and (min-width: 320px) {
  .service-common-container {
    height: 894px;
    .home-image {
      position: absolute;
      top: 5%;
      left: 15%;
      width: 70%;
      z-index: 5;

      animation: float 6s ease-in-out infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .service-common-content {
      z-index: 10;
      position: absolute;
      left: 5%;
      top: 37%;
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0;

      .csr-doc,
      .audio-video-prod,
      .still-motion {
        h3 {
          font-family: "Roboto", sans-serif;
          font-style: italic;
          font-size: 1.1rem;
          margin-bottom: 0.4rem;
          margin-top: 0.4rem;
          color: white;
          letter-spacing: 0.025em;
        }

        p {
          font-family: "Roboto", sans-serif;
          font-size: 0.8rem;
          font-weight: 400;
          margin-top: 0;
          letter-spacing: 0.025em;
          line-height: 1.1rem;
          color: white;
        }
      }
    }

    .parallax-container {
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: -5%;
      z-index: 4;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .service-common-container {
    height: 894px;
    .home-image {
      position: absolute;
      top: 10%;
      left: 15%;
      width: 70%;
      z-index: 5;

      animation: float 6s ease-in-out infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .service-common-content {
      z-index: 10;
      position: absolute;
      left: 5%;
      top: 37%;
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0;

      .csr-doc,
      .audio-video-prod,
      .still-motion {
        h3 {
          font-family: "Roboto", sans-serif;
          font-style: italic;
          font-size: 1rem;
          margin-bottom: 0.4rem;
          margin-top: 0.2rem;
          color: white;
          letter-spacing: 0.025em;
        }

        p {
          font-family: "Roboto", sans-serif;
          font-size: 0.8rem;
          font-weight: 400;
          margin-top: 0;
          margin-bottom: 0.6rem;
          letter-spacing: 0.025em;
          line-height: 1.1rem;
          color: white;
        }
      }
    }

    .parallax-container {
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: -5%;
      z-index: 4;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
