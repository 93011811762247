@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.contact-video-container {
  width: 100%;
  height: 100vh;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  video {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -100000;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .contact-header {
    position: absolute;
    z-index: 99;

    top: 10%;
    left: 8%;

    h2 {
      padding: 0;
      margin: 0;
      font-size: 2.8rem;
      color: white;
      font-family: "Roboto", sans-serif;
    }
  }

  .overlay-container {
    position: absolute;
    z-index: -10;
    width: 100%;
    height: 100vh;
    top: 60px;

    background: linear-gradient(
      180deg,
      rgba(0, 0, 23, 0) 0.18%,
      rgba(0, 0, 23, 0) 0.18%,
      rgba(0, 0, 23, 0.55) 85.9%,
      rgba(0, 0, 23, 0.6) 90.46%,
      rgba(0, 0, 23, 0.85) 95.03%,
      #000017 100%
    );
  }

  .contact-form {
    position: absolute;
    z-index: 1;
    width: 600px;
    height: 540px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    top: 25%;
    right: 10%;

    // left: 0;
    // right: 0;
    // margin-left: auto;
    // margin-right: auto;

    background: rgba(0, 0, 63, 0.33);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10.1px);
    -webkit-backdrop-filter: blur(10.1px);

    .btn {
      margin-top: 10px;
      padding: 10px 30px;

      background-image: linear-gradient(
        to right,
        #25aae1,
        #4481eb,
        #04befe,
        #3f86ed
      );
      box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);

      font-size: 12px;
      font-weight: 600;
      color: #fff;
      cursor: pointer;
      //margin: 20px;
      height: 50px;
      text-align: center;
      border: none;
      background-size: 300% 100%;
      border-radius: 5px;
      -o-transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;

      :hover {
        background-position: 100% 0;
        -o-transition: all 0.4s ease-in-out;
        -webkit-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
      }

      :focus {
        outline: none;
      }
    }

    h1 {
      margin: 0 0 10px 0;
      font-family: "Roboto", sans-serif;
      //font-size: 1.2rem;
      color: #fff;
    }

    .input-box {
      margin: 20px 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      ::placeholder {
        font-size: 14px;
        font-weight: 400;
        font-family: sans-serif;
      }

      label {
        font-family: "Roboto", sans-serif;
        margin: 0 0 10px 5px;
        font-weight: 500;
        //font-size: 0.9rem;
        color: #fff;
      }

      .form-control {
        padding: 5px 5px 5px 10px;
        height: 40px;
        width: 400px;
        border-radius: 10px;
        border: none;
        outline: none;
      }

      .textarea-control {
        resize: none;
        padding: 10px 10px;
        height: 100px;
        width: 400px;
        border-radius: 10px;
        border: none;
        outline: none;
      }
    }
  }

  .contact-logo {
    width: 30vw;
    left: 5%;
    top: 25%;
    z-index: 8;
    position: absolute;

    animation: float 6s ease-in-out infinite;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

@media screen and (max-width: 1440px) and (min-width: 1024px) {
  .contact-video-container {
    height: 900px;
    .contact-header {
      position: absolute;
      z-index: 99;

      top: 100px;
      left: 5%;

      h2 {
        padding: 0;
        margin: 0;
        font-size: 2rem;
        color: white;
        font-family: "Roboto", sans-serif;
      }
    }

    .contact-logo {
      width: 30%;
      left: 5%;
      top: 250px;
      z-index: 8;
      position: absolute;

      animation: float 6s ease-in-out infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .overlay-container {
      position: absolute;
      z-index: -10;
      width: 100%;
      height: 900px;
      top: 60px;

      background: linear-gradient(
        180deg,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0.55) 85.9%,
        rgba(0, 0, 23, 0.6) 90.46%,
        rgba(0, 0, 23, 0.85) 95.03%,
        #000017 100%
      );
    }

    .contact-form {
      position: absolute;
      z-index: 1;
      width: 600px;
      height: 540px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      top: 250px;
      right: 10%;

      background: rgba(0, 0, 63, 0.33);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(10px);

      .btn {
        margin-top: 10px;
        padding: 10px 30px;

        background-image: linear-gradient(
          to right,
          #25aae1,
          #4481eb,
          #04befe,
          #3f86ed
        );
        box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);

        font-size: 12px;
        font-weight: 600;
        color: #fff;
        cursor: pointer;
        //margin: 20px;
        height: 50px;
        text-align: center;
        border: none;
        background-size: 300% 100%;
        border-radius: 5px;
        -o-transition: all 0.4s ease-in-out;
        -webkit-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;

        :hover {
          background-position: 100% 0;
          -o-transition: all 0.4s ease-in-out;
          -webkit-transition: all 0.4s ease-in-out;
          transition: all 0.4s ease-in-out;
        }

        :focus {
          outline: none;
        }
      }

      h1 {
        margin: 0 0 10px 0;
        font-family: "Roboto", sans-serif;
        color: #fff;
      }

      .input-box {
        margin: 20px 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        ::placeholder {
          font-size: 14px;
          font-weight: 400;
          font-family: sans-serif;
        }

        label {
          font-family: "Roboto", sans-serif;
          margin: 0 0 10px 5px;
          font-weight: 500;
          color: #fff;
        }

        .form-control {
          padding: 5px 5px 5px 10px;
          height: 40px;
          width: 400px;
          border-radius: 10px;
          border: none;
          outline: none;
        }

        .textarea-control {
          resize: none;
          padding: 10px 10px;
          height: 100px;
          width: 400px;
          border-radius: 10px;
          border: none;
          outline: none;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .contact-video-container {
    height: 900px;
    .contact-header {
      position: absolute;
      z-index: 99;

      top: 80px;
      left: 5%;

      h2 {
        padding: 0;
        margin: 0;
        font-size: 1.6rem;
        color: white;
        font-family: "Roboto", sans-serif;
      }
    }

    .overlay-container {
      position: absolute;
      z-index: -10;
      width: 100%;
      height: 900px;
      top: 60px;

      background: linear-gradient(
        180deg,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0.55) 85.9%,
        rgba(0, 0, 23, 0.6) 90.46%,
        rgba(0, 0, 23, 0.85) 95.03%,
        #000017 100%
      );
    }

    .contact-form {
      position: absolute;
      z-index: 1;
      width: 500px;
      height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      top: 250px;
      right: 5%;

      background: rgba(0, 0, 63, 0.33);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(10.1px);
      -webkit-backdrop-filter: blur(10.1px);

      .btn {
        margin-top: 10px;
        padding: 5px 20px;

        background-image: linear-gradient(
          to right,
          #25aae1,
          #4481eb,
          #04befe,
          #3f86ed
        );
        box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);

        font-size: 12px;
        font-weight: 600;
        color: #fff;
        cursor: pointer;
        //margin: 20px;
        height: 50px;
        text-align: center;
        border: none;
        background-size: 300% 100%;
        border-radius: 5px;
        -o-transition: all 0.4s ease-in-out;
        -webkit-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;

        :hover {
          background-position: 100% 0;
          -o-transition: all 0.4s ease-in-out;
          -webkit-transition: all 0.4s ease-in-out;
          transition: all 0.4s ease-in-out;
        }

        :focus {
          outline: none;
        }
      }

      h1 {
        margin: 0 0 5px 0;
        font-size: 1.4rem;
        color: #fff;
      }

      .input-box {
        margin: 20px 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        ::placeholder {
          font-size: 14px;
          font-weight: 400;
          font-family: sans-serif;
        }

        label {
          margin: 0 0 10px 5px;
          font-weight: 500;
          font-size: 1rem;
          color: #fff;
        }

        .form-control {
          padding: 5px 5px 5px 10px;
          height: 35px;
          width: 400px;
          border-radius: 10px;
          border: none;
          outline: none;
        }

        .textarea-control {
          resize: none;
          padding: 10px 10px;
          height: 80px;
          width: 400px;
          border-radius: 10px;
          border: none;
          outline: none;
        }
      }
    }

    .contact-logo {
      width: 40%;
      left: 5%;
      top: 250px;
      z-index: 8;
      position: absolute;

      animation: float 6s ease-in-out infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: 768px) and (min-width: 425px) {
  .contact-video-container {
    height: 894px;
    .overlay-container {
      position: absolute;
      z-index: -10;
      width: 100%;
      top: 60px;
      height: 894px;

      background: linear-gradient(
        180deg,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0.55) 85.9%,
        rgba(0, 0, 23, 0.6) 90.46%,
        rgba(0, 0, 23, 0.85) 95.03%,
        #000017 100%
      );
    }

    .contact-header {
      position: absolute;
      z-index: 99;

      top: 80px;
      left: 5%;

      h2 {
        padding: 0;
        margin: 0;
        font-size: 1.6rem;
        color: white;
        font-family: "Roboto", sans-serif;
      }
    }

    .contact-form {
      position: absolute;
      z-index: 10;
      width: 440px;
      height: 440px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      top: 390px;

      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;

      background: rgba(0, 0, 63, 0.33);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(10.1px);
      -webkit-backdrop-filter: blur(10.1px);

      .btn {
        margin-top: 10px;
        padding: 4px 15px;

        background-image: linear-gradient(
          to right,
          #25aae1,
          #4481eb,
          #04befe,
          #3f86ed
        );
        box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);

        font-size: 12px;
        font-weight: 600;
        color: #fff;
        cursor: pointer;
        //margin: 20px;
        height: 50px;
        text-align: center;
        border: none;
        background-size: 300% 100%;
        border-radius: 5px;
        -o-transition: all 0.4s ease-in-out;
        -webkit-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;

        :hover {
          background-position: 100% 0;
          -o-transition: all 0.4s ease-in-out;
          -webkit-transition: all 0.4s ease-in-out;
          transition: all 0.4s ease-in-out;
        }

        :focus {
          outline: none;
        }
      }

      h1 {
        margin: 0 0 10px 0;
        font-size: 1.2rem;
        color: #fff;
      }

      .input-box {
        margin: 15px 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        ::placeholder {
          font-size: 12px;
          font-weight: 400;
          font-family: sans-serif;
        }

        label {
          margin: 0 0 10px 5px;
          font-weight: 500;
          font-size: 0.9rem;
          color: #fff;
        }

        .form-control {
          padding: 5px 5px 5px 10px;
          height: 28px;
          width: 320px;
          border-radius: 5px;
          border: none;
          outline: none;
        }

        .textarea-control {
          resize: none;
          padding: 10px 10px;
          height: 50px;
          width: 320px;
          border-radius: 5px;
          border: none;
          outline: none;
        }
      }
    }

    .contact-logo {
      width: 40%;
      top: 100px;
      z-index: 8;
      position: absolute;

      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;

      animation: float 6s ease-in-out infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: 425px) and (min-width: 320px) {
  .contact-video-container {
    height: 894px;
    .overlay-container {
      position: absolute;
      z-index: -10;
      width: 100%;
      height: 894px;
      top: 60px;

      background: linear-gradient(
        180deg,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0.55) 85.9%,
        rgba(0, 0, 23, 0.6) 90.46%,
        rgba(0, 0, 23, 0.85) 95.03%,
        #000017 100%
      );
    }

    .contact-header {
      position: absolute;
      z-index: 99;

      top: 80px;
      left: 5%;

      h2 {
        padding: 0;
        margin: 0;
        font-size: 1.6rem;
        color: white;
        font-family: "Roboto", sans-serif;
      }
    }

    .contact-logo {
      width: 50%;
      position: absolute;
      top: 150px;
      z-index: 8;

      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;

      animation: float 6s ease-in-out infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .contact-form {
      position: absolute;
      z-index: 210;
      width: 340px;
      height: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      top: 400px;

      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;

      background: rgba(0, 0, 63, 0.33);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(10.1px);
      -webkit-backdrop-filter: blur(10.1px);

      .btn {
        margin-top: 10px;
        padding: 4px 15px;

        background-image: linear-gradient(
          to right,
          #25aae1,
          #4481eb,
          #04befe,
          #3f86ed
        );
        box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);

        font-size: 12px;
        font-weight: 600;
        color: #fff;
        cursor: pointer;
        height: 50px;
        text-align: center;
        border: none;
        background-size: 300% 100%;
        border-radius: 5px;

        :focus {
          outline: none;
        }
      }

      h1 {
        margin: 0 0 10px 0;
        font-size: 1.1rem;
        color: #fff;
      }

      .input-box {
        margin: 10px 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        ::placeholder {
          font-size: 12px;
          font-weight: 400;
          font-family: sans-serif;
        }

        label {
          margin: 0 0 10px 5px;
          font-weight: 500;
          font-size: 0.9rem;
          color: #fff;
        }

        .form-control {
          padding: 5px 5px 5px 10px;
          height: 25px;
          width: 240px;
          border-radius: 5px;
          border: none;
          outline: none;
        }

        .textarea-control {
          resize: none;
          padding: 10px 10px;
          height: 40px;
          width: 240px;
          border-radius: 5px;
          border: none;
          outline: none;
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .contact-video-container {
    height: 894px;
    .overlay-container {
      position: absolute;
      z-index: -10;
      width: 100%;
      height: 894px;
      top: 60px;

      background: linear-gradient(
        180deg,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0.55) 85.9%,
        rgba(0, 0, 23, 0.6) 90.46%,
        rgba(0, 0, 23, 0.85) 95.03%,
        #000017 100%
      );
    }

    .contact-header {
      position: absolute;
      z-index: 99;

      top: 80px;
      left: 5%;

      h2 {
        padding: 0;
        margin: 0;
        font-size: 1.6rem;
        color: white;
        font-family: "Roboto", sans-serif;
      }
    }

    .contact-logo {
      width: 50%;
      position: absolute;
      top: 150px;
      z-index: 8;

      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;

      animation: float 6s ease-in-out infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .contact-form {
      position: absolute;
      z-index: 210;
      width: 320px;
      height: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      top: 400px;

      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;

      background: rgba(0, 0, 63, 0.33);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(10.1px);
      -webkit-backdrop-filter: blur(10.1px);

      .btn {
        margin-top: 10px;
        padding: 4px 15px;

        background-image: linear-gradient(
          to right,
          #25aae1,
          #4481eb,
          #04befe,
          #3f86ed
        );
        box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);

        font-size: 12px;
        font-weight: 600;
        color: #fff;
        cursor: pointer;
        height: 50px;
        text-align: center;
        border: none;
        background-size: 300% 100%;
        border-radius: 5px;

        :focus {
          outline: none;
        }
      }

      h1 {
        margin: 0 0 10px 0;
        font-size: 1.1rem;
        color: #fff;
      }

      .input-box {
        margin: 10px 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        ::placeholder {
          font-size: 12px;
          font-weight: 400;
          font-family: sans-serif;
        }

        label {
          margin: 0 0 10px 5px;
          font-weight: 500;
          font-size: 0.9rem;
          color: #fff;
        }

        .form-control {
          padding: 5px 5px 5px 10px;
          height: 25px;
          width: 240px;
          border-radius: 5px;
          border: none;
          outline: none;
        }

        .textarea-control {
          resize: none;
          padding: 10px 10px;
          height: 40px;
          width: 240px;
          border-radius: 5px;
          border: none;
          outline: none;
        }
      }
    }
  }
}
