//@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.about-video-container {
  width: 100%;
  height: 100vh;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  video {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -10;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .about-header {
    position: absolute;
    z-index: 99;

    top: 10%;
    left: 8%;
    // right: 10%;

    h2 {
      padding: 0;
      margin: 0;
      font-size: 2.8rem;
      color: white;
      font-family: "Roboto", sans-serif;
    }
  }

  .overlay-container {
    position: absolute;
    z-index: -10;
    width: 100%;
    height: 100vh;
    top: 60px;

    background: linear-gradient(
      180deg,
      rgba(0, 0, 23, 0) 0.18%,
      rgba(0, 0, 23, 0) 0.18%,
      rgba(0, 0, 23, 0.55) 85.9%,
      rgba(0, 0, 23, 0.6) 90.46%,
      rgba(0, 0, 23, 0.85) 95.03%,
      #000017 100%
    );
  }

  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 60px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: white;
    z-index: -1;
    transition: ease;
    overflow: hidden;

    .psy-reel-logo {
      width: 30%;
      position: absolute;
      left: 5%;
      top: 20%;
      z-index: 8;

      animation: float 6s ease-in-out infinite;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .about-content {
      z-index: 10;
      position: absolute;
      right: 10%;
      width: 35%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 30px 50px;
      margin: 0;

      background: rgba(0, 0, 63, 0.33);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(10.1px);
      -webkit-backdrop-filter: blur(10.1px);

      p {
        font-family: "Roboto", sans-serif;
        margin-top: 20px;
        font-size: 1.4rem;
        font-weight: 400;
        letter-spacing: 0.025em;
        line-height: 2rem;
      }
    }
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

@media screen and (max-width: 1440px) and (min-width: 1024px) {
  .about-video-container {
    height: 900px;
    .overlay-container {
      position: absolute;
      z-index: -10;
      width: 100%;
      height: 900px;
      top: 60px;

      background: linear-gradient(
        180deg,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0.55) 85.9%,
        rgba(0, 0, 23, 0.6) 90.46%,
        rgba(0, 0, 23, 0.85) 95.03%,
        #000017 100%
      );
    }

    .content {
      height: 900px;
      .about-content {
        z-index: 10;
        position: absolute;
        right: 5%;
        width: 45%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 30px 50px;
        margin: 0;

        background: rgba(0, 0, 63, 0.33);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10.1px);
        -webkit-backdrop-filter: blur(10.1px);

        p {
          margin-top: 20px;
          font-size: 1.4rem;
          font-weight: 500;
          letter-spacing: 0.025em;
          line-height: 2rem;
        }
      }

      .psy-reel-logo {
        width: 30%;
        position: absolute;
        left: 5%;
        z-index: 8;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .about-header {
      position: absolute;
      z-index: 99;

      top: 100px;
      left: 5%;

      h2 {
        margin: 0;
        padding: 0;
        font-size: 2rem;
        color: white;
        font-family: "Roboto", sans-serif;
      }
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .about-video-container {
    height: 900px;
    .overlay-container {
      position: absolute;
      z-index: -10;
      width: 100%;
      height: 900px;
      top: 60px;

      background: linear-gradient(
        180deg,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0.55) 85.9%,
        rgba(0, 0, 23, 0.6) 90.46%,
        rgba(0, 0, 23, 0.85) 95.03%,
        #000017 100%
      );
    }

    .content {
      height: 900px;
      .about-content {
        z-index: 10;
        position: absolute;
        right: 5%;
        width: 45vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 35px;
        margin: 0;

        background: rgba(0, 0, 63, 0.33);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10.1px);
        -webkit-backdrop-filter: blur(10.1px);

        p {
          margin-top: 10px;
          font-size: 1rem;
          font-weight: 600;
          letter-spacing: 0.025em;
          line-height: 1.75rem;
        }
      }

      .psy-reel-logo {
        width: 30%;
        position: absolute;
        left: 5%;
        top: 30%;
        z-index: 8;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .about-header {
      position: absolute;
      z-index: 99;

      top: 80px;
      left: 5%;

      h2 {
        margin: 0;
        padding: 0;
        font-size: 1.6rem;
        color: white;
        font-family: "Roboto", sans-serif;
      }
    }
  }
}

@media screen and (max-width: 768px) and (min-width: 425px) {
  .about-video-container {
    height: 894px;

    .content {
      position: absolute;
      width: 100%;
      height: 894px;
      top: 60px;
      display: flex;
      align-items: center;
      color: white;
      z-index: -1;
      transition: ease;
      overflow: hidden;

      .psy-reel-logo {
        width: 40%;
        position: absolute;
        top: 10%;
        z-index: 8;

        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .about-content {
        z-index: 1000;
        position: absolute;
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 15px 25px;

        top: 50%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;

        background: rgba(0, 0, 63, 0.33);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10.1px);
        -webkit-backdrop-filter: blur(10.1px);

        p {
          margin-top: 10px;
          font-size: 1rem;
          font-weight: 600;
          letter-spacing: 0.025em;
          line-height: 1.75rem;
        }
      }
    }

    .about-header {
      position: absolute;
      z-index: 99;

      top: 80px;
      left: 5%;

      h2 {
        margin: 0;
        padding: 0;
        font-size: 1.6rem;
        color: white;
        font-family: "Roboto", sans-serif;
      }
    }

    .overlay-container {
      position: absolute;
      z-index: -10;
      width: 100%;
      top: 60px;
      height: 894px;

      background: linear-gradient(
        180deg,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0.55) 85.9%,
        rgba(0, 0, 23, 0.6) 90.46%,
        rgba(0, 0, 23, 0.85) 95.03%,
        #000017 100%
      );
    }
  }
}

@media screen and (max-width: 425px) and (min-width: 320px) {
  .about-video-container {
    height: 894px;
    .about-header {
      position: absolute;
      z-index: 99;

      top: 80px;
      left: 5%;

      h2 {
        padding: 0;
        margin: 0;
        font-size: 1.6rem;
        color: white;
        font-family: "Roboto", sans-serif;
      }
    }

    .content {
      position: absolute;
      width: 100%;
      height: 894px;
      top: 60px;
      display: flex;
      align-items: center;
      color: white;
      z-index: -1;
      transition: ease;
      overflow: hidden;

      .psy-reel-logo {
        width: 55%;
        position: absolute;
        top: 7%;
        z-index: 8;

        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .about-content {
        z-index: 200;
        position: absolute;
        top: 35%;
        width: 85%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 15px 25px;

        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;

        background: rgba(0, 0, 63, 0.33);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10.1px);
        -webkit-backdrop-filter: blur(10.1px);

        p {
          margin-top: 5px;
          font-size: 1rem;
          font-weight: 600;
          letter-spacing: 0.025em;
          line-height: 1.75rem;
        }
      }
    }

    .overlay-container {
      position: absolute;
      z-index: -10;
      width: 100%;
      top: 60px;
      height: 894px;

      background: linear-gradient(
        180deg,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0.55) 85.9%,
        rgba(0, 0, 23, 0.6) 90.46%,
        rgba(0, 0, 23, 0.85) 95.03%,
        #000017 100%
      );
    }
  }
}

@media screen and (max-width: 320px) {
  .about-video-container {
    height: 894px;
    .about-header {
      position: absolute;
      z-index: 99;
      top: 80px;
      left: 5%;

      h2 {
        padding: 0;
        margin: 0;
        font-size: 1.6rem;
        color: white;
        font-family: "Roboto", sans-serif;
      }
    }

    .content {
      position: absolute;
      width: 100%;
      height: 894px;
      top: 60px;
      display: flex;
      align-items: center;
      color: white;
      z-index: -1;
      transition: ease;
      overflow: hidden;

      .psy-reel-logo {
        width: 65%;
        position: absolute;
        //left: 25%;
        top: 10%;
        z-index: 8;

        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .about-content {
        z-index: 200;
        position: absolute;
        bottom: 18%;
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 15px 25px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;

        background: rgba(0, 0, 63, 0.33);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10.1px);
        -webkit-backdrop-filter: blur(10.1px);

        p {
          margin-top: 5px;
          font-size: 1rem;
          font-weight: 600;
          letter-spacing: 0.025em;
          line-height: 1.5rem;
        }
      }
    }

    .overlay-container {
      position: absolute;
      z-index: -10;
      width: 100%;
      height: 894px;
      top: 60px;

      background: linear-gradient(
        180deg,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0.55) 85.9%,
        rgba(0, 0, 23, 0.6) 90.46%,
        rgba(0, 0, 23, 0.85) 95.03%,
        #000017 100%
      );
    }
  }
}
