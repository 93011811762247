@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap");

.common-container-layout {
  margin: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000017;
  position: relative;
  overflow: hidden;

  .overlay-container {
    position: absolute;
    z-index: 2;

    width: 100%;
    height: 100%;

    background: linear-gradient(
      180deg,
      rgba(0, 0, 23, 0.28) 0%,
      rgba(0, 0, 23, 0.28) 100%
    );
  }

  .parallex-container {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    z-index: 4;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .home-image {
    position: absolute;
    top: 10px;
    width: 40%;
    z-index: 1;
    animation: float 6s ease-in-out infinite;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .flower1-parallex-container {
    position: absolute;
    width: 35%;
    left: -5%;
    bottom: -18%;
    z-index: 2;
    transform: rotate(10deg);

    animation-name: flower-one-main;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    transition-timing-function: ease-in-out;
    animation-direction: alternate;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .flower2-parallex-container {
    position: absolute;
    width: 35%;
    right: -5%;
    bottom: -18%;
    z-index: 2;
    transform: rotate(-10deg);

    animation-name: flower-two-main;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    transition-timing-function: ease-in-out;
    animation-direction: alternate;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .home-content-quote {
    position: absolute;
    z-index: 99;
    width: 55%;
    display: flex;
    align-items: center;

    p {
      font-size: 4.6rem;
      color: white;
      font-family: "Open Sans", sans-serif;

      text-transform: capitalize;

      -webkit-text-stroke: 1px black;
    }
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

@media screen and (max-width: 1440px) and (min-width: 1024px) {
  .common-container-layout {
    height: 1345px;
    .home-image {
      position: absolute;
      top: 100px;
      width: 60%;
      z-index: 1;
      animation: float 6s ease-in-out infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .flower1-parallex-container {
      position: absolute;
      width: 40%;
      left: -25px;
      bottom: -90px;
      z-index: 2;
      transform: rotate(10deg);

      animation-name: flower-one;
      animation-duration: 6s;
      animation-iteration-count: infinite;
      transition-timing-function: ease-in-out;
      animation-direction: alternate;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .flower2-parallex-container {
      position: absolute;
      width: 40%;
      right: -25px;
      bottom: -90px;
      z-index: 2;
      transform: rotate(-10deg);

      animation-name: flower-two;
      animation-duration: 6s;
      animation-iteration-count: infinite;
      transition-timing-function: ease-in-out;
      animation-direction: alternate;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .home-content-quote {
      position: absolute;
      z-index: 99;
      width: 80%;
      top: 320px;
      display: flex;
      align-items: center;

      p {
        font-size: 4rem;
        color: white;
        font-family: "Open Sans", sans-serif;
        text-transform: capitalize;
        -webkit-text-stroke: 1px black;
      }
    }

    .parallex-container {
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: -7%;
      z-index: 4;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .common-container-layout {
    height: 1080px;
    .home-image {
      position: absolute;
      top: 60px;
      width: 65%;
      z-index: 1;
      animation: float 6s ease-in-out infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .flower1-parallex-container {
      position: absolute;
      width: 50%;
      left: -30px;
      bottom: -90px;
      z-index: 2;
      transform: rotate(10deg);

      animation-name: flower-one;
      animation-duration: 6s;
      animation-iteration-count: infinite;
      transition-timing-function: ease-in-out;
      animation-direction: alternate;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .flower2-parallex-container {
      position: absolute;
      width: 50%;
      right: -30px;
      bottom: -90px;
      z-index: 2;
      transform: rotate(-10deg);

      animation-name: flower-two;
      animation-duration: 6s;
      animation-iteration-count: infinite;
      transition-timing-function: ease-in-out;
      animation-direction: alternate;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .home-content-quote {
      position: absolute;
      z-index: 99;
      width: 70%;
      top: 240px;
      display: flex;
      align-items: center;

      p {
        font-size: 3rem;
        color: white;
        font-family: "Open Sans", sans-serif;
        text-transform: capitalize;
        -webkit-text-stroke: 1px black;
      }
    }

    .parallex-container {
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: -7%;
      z-index: 4;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: 768px) and (min-width: 425px) {
  .common-container-layout {
    height: 800px;
    .home-image {
      position: absolute;
      top: 80px;
      width: 65%;
      z-index: 1;
      animation: float 6s ease-in-out infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .flower1-parallex-container {
      position: absolute;
      width: 50%;
      left: -20px;
      bottom: -40px;
      z-index: 2;
      transform: rotate(10deg);

      animation-name: flower-one;
      animation-duration: 6s;
      animation-iteration-count: infinite;
      transition-timing-function: ease-in-out;
      animation-direction: alternate;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .flower2-parallex-container {
      position: absolute;
      width: 50%;
      right: -20px;
      bottom: -40px;
      z-index: 2;
      transform: rotate(-10deg);

      animation-name: flower-two;
      animation-duration: 6s;
      animation-iteration-count: infinite;
      transition-timing-function: ease-in-out;
      animation-direction: alternate;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .home-content-quote {
      position: absolute;
      z-index: 99;
      width: 70%;
      top: 190px;
      display: flex;
      align-items: center;

      p {
        font-size: 2.2rem;
        color: white;
        font-family: "Open Sans", sans-serif;
        text-transform: capitalize;
        -webkit-text-stroke: 1px black;
      }
    }

    .parallex-container {
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: -7%;
      z-index: 4;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: 425px) and (min-width: 375px) {
  .common-container-layout {
    height: 700px;
    .home-image {
      position: absolute;
      top: 5%;
      width: 90%;
      z-index: 1;
      animation: float 6s ease-in-out infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .flower1-parallex-container {
      position: absolute;
      width: 80%;
      left: -20%;
      bottom: -8%;
      z-index: 2;
      transform: rotate(10deg);

      animation-name: flower-one;
      animation-duration: 6s;
      animation-iteration-count: infinite;
      transition-timing-function: ease-in-out;
      animation-direction: alternate;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .flower2-parallex-container {
      position: absolute;
      width: 80%;
      right: -20%;
      bottom: -8%;
      z-index: 2;
      transform: rotate(-10deg);

      animation-name: flower-two;
      animation-duration: 6s;
      animation-iteration-count: infinite;
      transition-timing-function: ease-in-out;
      animation-direction: alternate;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .home-content-quote {
      position: absolute;
      z-index: 99;
      width: 85%;
      top: 16%;
      display: flex;
      align-items: center;

      p {
        font-size: 1.8rem;
        color: white;
        font-family: "Open Sans", sans-serif;

        text-transform: capitalize;

        -webkit-text-stroke: 1px black;
      }
    }

    .parallex-container {
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: -7%;
      z-index: 4;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .common-container-layout {
    height: 680px;
    .home-image {
      position: absolute;
      top: 5%;
      width: 90%;
      z-index: 1;
      animation: float 6s ease-in-out infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .flower1-parallex-container {
      position: absolute;
      width: 80%;
      left: -20%;
      bottom: 0;
      z-index: 2;
      transform: rotate(10deg);

      animation-name: flower-one;
      animation-duration: 6s;
      animation-iteration-count: infinite;
      transition-timing-function: ease-in-out;
      animation-direction: alternate;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .flower2-parallex-container {
      position: absolute;
      width: 80%;
      right: -20%;
      bottom: 0;
      z-index: 2;
      transform: rotate(-10deg);

      animation-name: flower-two;
      animation-duration: 6s;
      animation-iteration-count: infinite;
      transition-timing-function: ease-in-out;
      animation-direction: alternate;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .home-content-quote {
      position: absolute;
      z-index: 99;
      width: 80%;
      top: 14%;
      display: flex;
      align-items: center;

      p {
        font-size: 1.6rem;
        color: white;
        font-family: "Open Sans", sans-serif;

        text-transform: capitalize;

        -webkit-text-stroke: 1px black;
      }
    }

    .parallex-container {
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: -6%;
      z-index: 4;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@keyframes flower-one {
  0% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(5deg);
  }
}

@keyframes flower-two {
  0% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(-5deg);
  }
}

@keyframes flower-one-main {
  0% {
    transform: rotate(20deg);
  }
  100% {
    transform: rotate(10deg);
  }
}

@keyframes flower-two-main {
  0% {
    transform: rotate(-20deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}
