.work-common-container {
  margin: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000017;
  position: relative;
  overflow: hidden;

  .overlay-container {
    position: absolute;
    z-index: 2;

    width: 100%;
    height: 100%;

    background: linear-gradient(
      180deg,
      rgba(0, 0, 23, 0.28) 0%,
      rgba(0, 0, 23, 0.28) 100%
    );
  }

  .parallax-container {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    z-index: 4;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .home-image {
    position: absolute;
    //top: 0;
    right: 0;
    width: 45%;
    z-index: 5;
    animation: float 6s ease-in-out infinite;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .work-youtube-content {
    position: absolute;
    z-index: 99;

    left: 2%;

    width: 60%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: space-around;
    gap: 60px 10px;

    iframe {
      width: 24vw;
      height: 26.5vh;
      border-radius: 5px;
    }
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

@media screen and (max-width: 1440px) and (min-width: 1024px) {
  .work-common-container {
    height: 900px;
    .home-image {
      position: absolute;
      top: 4%;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;

      width: 35%;
      z-index: 5;
      animation: float 6s ease-in-out infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .work-youtube-content {
      z-index: 100;
      position: absolute;
      top: 40%;

      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;

      width: 80%;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-content: space-around;
      gap: 60px 10px;

      iframe {
        width: 400px;
        height: 220px;
        border-radius: 5px;
      }
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .work-common-container {
    height: 984px;
    .home-image {
      position: absolute;
      top: 5%;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;

      width: 40%;
      z-index: 5;
      animation: float 6s ease-in-out infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .work-youtube-content {
      z-index: 100;
      position: absolute;
      top: 40%;

      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;

      width: 90%;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-content: space-around;
      gap: 60px 10px;

      iframe {
        width: 350px;
        height: 190px;
        border-radius: 5px;
      }
    }
  }
}

@media screen and (max-width: 768px) and (min-width: 425px) {
  .work-common-container {
    height: 894px;
    .home-image {
      position: absolute;
      top: 5%;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;

      width: 50%;
      z-index: 5;
      animation: float 6s ease-in-out infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .work-youtube-content {
      z-index: 100;
      position: absolute;
      top: 220px;

      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;

      width: 90%;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-content: space-around;
      gap: 20px 10px;

      iframe {
        width: 240px;
        height: 140px;
        border-radius: 5px;
      }
    }
  }
}

@media screen and (max-width: 425px) and (min-width: 320px) {
  .work-common-container {
    height: 894px;
    .home-image {
      position: absolute;
      top: 5%;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;

      width: 45%;
      z-index: 5;
      animation: float 6s ease-in-out infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .work-youtube-content {
      position: absolute;
      z-index: 99;

      top: 20%;

      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;

      left: 2%;

      width: 90%;

      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-content: space-around;
      gap: 20px;

      iframe {
        width: 280px;
        height: 140px;
        border-radius: 5px;
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .work-common-container {
    height: 894px;
    .home-image {
      position: absolute;
      top: 5%;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;

      width: 45%;
      z-index: 5;
      animation: float 6s ease-in-out infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .work-youtube-content {
      position: absolute;
      z-index: 99;

      top: 20%;

      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;

      left: 2%;

      width: 90%;

      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-content: space-around;
      gap: 20px;

      iframe {
        width: 240px;
        height: 140px;
        border-radius: 5px;
      }
    }
  }
}
