@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

.navbar-links {
  z-index: 10;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0 30px 0 0;

  ul {
    padding: 0;
    margin: 0 10px;
    display: flex;
    list-style: none;
    align-items: center;
    justify-content: space-between;

    li {
      margin: 20px 20px 20px 20px;
      color: #e2e8f0;
      cursor: pointer;

      a {
        letter-spacing: 0.025rem;
        font-family: "Lato", sans-serif;
        font-weight: 600;
        text-decoration: none;
        color: white;
        padding-bottom: 5px;
        border-bottom: 2px solid transparent;
        transition: all 150ms linear;

        padding-left: 3px;
        padding-right: 3px;

        &:hover {
          border-bottom: 2px solid white;
        }

        &:active {
          border-bottom: 2px solid white;
        }
      }
    }
  }
}
