@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap");

.home-video-container {
  width: 100%;
  height: 100vh;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .video-background {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;

    z-index: -100;
  }

  .home-content {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .parallex-container {
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: -17%;
      z-index: 2;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .flower1-parallex-container {
      position: absolute;
      width: 35%;
      left: 0;
      bottom: -35%;
      z-index: 1;
      transform: rotate(20deg);
      overflow: hidden;
      animation-name: flower-one;
      animation-duration: 4s;
      animation-iteration-count: infinite;
      transition-timing-function: linear;
      animation-direction: alternate;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .flower2-parallex-container {
      position: absolute;
      width: 35%;
      right: 0;
      bottom: -35%;
      z-index: 1;
      transform: rotate(-20deg);
      overflow: hidden;

      animation-name: flower-two;
      animation-duration: 4s;
      animation-iteration-count: infinite;
      transition-timing-function: linear;
      animation-direction: alternate;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .home-content-quote {
      position: absolute;
      z-index: 99;
      width: 40%;
      bottom: 5%;

      p {
        font-size: 1.3rem;
        color: white;
        font-family: "Open Sans", sans-serif;
      }
    }
  }

  .overlay-container {
    position: absolute;
    z-index: -10;
    width: 100%;
    height: 100vh;
    top: 60px;

    background: linear-gradient(
      180deg,
      rgba(0, 0, 23, 0) 0.18%,
      rgba(0, 0, 23, 0) 0.18%,
      rgba(0, 0, 23, 0.55) 85.9%,
      rgba(0, 0, 23, 0.6) 90.46%,
      rgba(0, 0, 23, 0.85) 95.03%,
      #000017 100%
    );
  }
}

@keyframes flower-one {
  0% {
    transform: rotate(18deg);
  }
  100% {
    transform: rotate(20.5deg);
  }
}

@keyframes flower-two {
  0% {
    transform: rotate(-18deg);
  }
  100% {
    transform: rotate(-20.5deg);
  }
}

@media screen and (max-width: 1440px) and (min-width: 1024px) {
  .home-video-container {
    .overlay-container {
      position: relative;
      z-index: 99;
      width: 100%;

      height: 100vh;
      top: 0;

      background: linear-gradient(
        180deg,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0.55) 85.9%,
        rgba(0, 0, 23, 0.6) 90.46%,
        rgba(0, 0, 23, 0.85) 95.03%,
        #000017 100%
      );
    }
    .home-content {
      .flower1-parallex-container {
        position: absolute;
        width: 35%;
        left: 0;
        bottom: -20%;
        z-index: 1;
        transform: rotate(20deg);
        overflow: hidden;
        animation-name: flower-one;
        animation-duration: 4s;
        animation-iteration-count: infinite;
        transition-timing-function: linear;
        animation-direction: alternate;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .flower2-parallex-container {
        position: absolute;
        width: 35%;
        right: 0;
        bottom: -20%;
        z-index: 1;
        transform: rotate(-20deg);
        overflow: hidden;

        animation-name: flower-two;
        animation-duration: 4s;
        animation-iteration-count: infinite;
        transition-timing-function: linear;
        animation-direction: alternate;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .home-video-container {
    .home-content {
      .home-title {
        width: 70%;
        top: 10%;

        h1 {
          text-align: center;
          margin: 0;
          color: white;
          font-size: 5.8rem;
          font-weight: 900;
          letter-spacing: 0.03em;
          line-height: 6.6rem;
          text-transform: uppercase;
          font-family: "Open Sans", sans-serif;

          text-shadow: 0px 0px 19px rgba(0, 0, 0, 0.8);
        }
      }
    }

    .overlay-container {
      position: absolute;
      z-index: 99;
      width: 100%;
      // height: 20vh;
      // top: 87%;
      // background: linear-gradient(
      //   180.21deg,
      //   rgba(0, 0, 23, 0) 0.18%,
      //   rgba(0, 0, 23, 0.6) 49.16%,
      //   rgba(0, 0, 23, 0.9) 81.81%,
      //   #000017 99.82%
      // );

      height: 100vh;
      top: 60px;

      background: linear-gradient(
        180deg,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0.55) 85.9%,
        rgba(0, 0, 23, 0.6) 90.46%,
        rgba(0, 0, 23, 0.85) 95.03%,
        #000017 100%
      );
    }
  }
}

@media screen and (max-width: 768px) and (min-width: 425px) {
  .home-video-container {
    height: 270px;
    .video-background {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;

      z-index: -100;
    }
    .home-content {
      .home-title {
        width: 70%;
        top: 10%;

        h1 {
          text-align: center;
          margin: 0;
          color: white;
          font-size: 5.8rem;
          font-weight: 900;
          letter-spacing: 0.03em;
          line-height: 6.6rem;
          text-transform: uppercase;
          font-family: "Open Sans", sans-serif;

          text-shadow: 0px 0px 19px rgba(0, 0, 0, 0.8);
        }
      }
    }

    .overlay-container {
      position: absolute;
      z-index: 99;
      width: 100%;
      // height: 20vh;
      // bottom: -8%;
      // background: linear-gradient(
      //   180.21deg,
      //   rgba(0, 0, 23, 0) 0.18%,
      //   rgba(0, 0, 23, 0.6) 49.16%,
      //   rgba(0, 0, 23, 0.9) 81.81%,
      //   #000017 99.82%
      // );

      height: 270px;
      // height: 100vh;
      top: 60px;

      background: linear-gradient(
        180deg,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0.55) 85.9%,
        rgba(0, 0, 23, 0.6) 90.46%,
        rgba(0, 0, 23, 0.85) 95.03%,
        #000017 100%
      );
    }
  }
}

@media screen and (max-width: 425px) and (min-width: 320px) {
  .home-video-container {
    width: 100%;
    height: 200px;
    margin: 0;
    display: flex;
    .video-background {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: contain;

      z-index: -100;
    }

    .overlay-container {
      position: absolute;
      z-index: 99;
      width: 100%;
      height: 200px;
      top: 60px;

      background: linear-gradient(
        180deg,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0.55) 85.9%,
        rgba(0, 0, 23, 0.6) 90.46%,
        rgba(0, 0, 23, 0.85) 95.03%,
        #000017 100%
      );
    }
  }
}

@media screen and (max-width: 320px) {
  .home-video-container {
    width: 100%;
    height: 20vh;
    margin: 0;
    display: flex;
    .video-background {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: contain;

      z-index: -100;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .overlay-container {
      position: absolute;
      z-index: 99;
      width: 100%;

      height: 20vh;
      top: 60px;

      background: linear-gradient(
        180deg,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0.55) 85.9%,
        rgba(0, 0, 23, 0.6) 90.46%,
        rgba(0, 0, 23, 0.85) 95.03%,
        #000017 100%
      );
    }
  }
}
