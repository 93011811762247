.work-video-container {
  width: 100%;
  height: 100vh;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  video {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -10;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .work-header {
    position: absolute;
    z-index: 99;

    top: 10%;
    left: 8%;

    h2 {
      padding: 0;
      margin: 0;
      font-size: 2.8rem;
      color: white;
      font-family: "Roboto", sans-serif;
    }
  }

  .overlay-container {
    position: absolute;
    z-index: -10;
    width: 100%;
    height: 100vh;
    top: 60px;

    background: linear-gradient(
      180deg,
      rgba(0, 0, 23, 0) 0.18%,
      rgba(0, 0, 23, 0) 0.18%,
      rgba(0, 0, 23, 0.55) 85.9%,
      rgba(0, 0, 23, 0.6) 90.46%,
      rgba(0, 0, 23, 0.85) 95.03%,
      #000017 100%
    );
  }

  .psy-reel-logo {
    width: 35vw;
    position: absolute;
    left: 5%;
    top: 20%;
    z-index: 8;

    animation: float 6s ease-in-out infinite;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .youtube-video-container {
    z-index: 100;
    position: absolute;
    top: 25%;
    right: 10%;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: space-around;
    gap: 60px 10px;

    iframe {
      width: 24vw;
      height: 26.5vh;
      border-radius: 5px;
    }
  }
}

@media screen and (max-width: 1440px) and (min-width: 1024px) {
  .work-video-container {
    height: 900px;
    .work-header {
      position: absolute;
      z-index: 99;

      top: 100px;
      left: 5%;

      h2 {
        padding: 0;
        margin: 0;
        font-size: 2rem;
        color: white;
        font-family: "Roboto", sans-serif;
      }
    }

    .psy-reel-logo {
      width: 35vw;
      position: absolute;
      left: 5%;
      top: 250px;
      z-index: 8;

      animation: float 6s ease-in-out infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .overlay-container {
      position: absolute;
      z-index: -10;
      width: 100%;
      height: 900px;
      top: 60px;

      background: linear-gradient(
        180deg,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0.55) 85.9%,
        rgba(0, 0, 23, 0.6) 90.46%,
        rgba(0, 0, 23, 0.85) 95.03%,
        #000017 100%
      );
    }

    .youtube-video-container {
      z-index: 100;
      position: absolute;
      top: 250px;

      width: 40%;

      right: 5%;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: space-around;
      gap: 60px 10px;

      iframe {
        width: 400px;
        height: 220px;
        border-radius: 5px;
      }
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .work-video-container {
    height: 900px;
    .work-header {
      position: absolute;
      z-index: 99;

      top: 80px;
      left: 5%;

      h2 {
        padding: 0;
        margin: 0;
        font-size: 1.6rem;
        color: white;
        font-family: "Roboto", sans-serif;
      }
    }

    .psy-reel-logo {
      width: 40%;
      position: absolute;
      top: 150px;
      z-index: 8;
      animation: float 6s ease-in-out infinite;

      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .overlay-container {
      position: absolute;
      z-index: -10;
      width: 100%;
      height: 900px;
      top: 60px;

      background: linear-gradient(
        180deg,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0.55) 85.9%,
        rgba(0, 0, 23, 0.6) 90.46%,
        rgba(0, 0, 23, 0.85) 95.03%,
        #000017 100%
      );
    }

    .youtube-video-container {
      z-index: 100;
      position: absolute;
      top: 600px;

      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;

      width: 90%;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-content: space-around;
      gap: 60px 10px;

      iframe {
        width: 380px;
        height: 210px;
        border-radius: 5px;
      }
    }
  }
}

@media screen and (max-width: 768px) and (min-width: 425px) {
  .work-video-container {
    height: 894px;
    .work-header {
      position: absolute;
      z-index: 99;

      top: 80px;
      left: 5%;

      h2 {
        margin: 0;
        padding: 0;
        font-size: 1.6rem;
        color: white;
        font-family: "Roboto", sans-serif;
      }
    }

    .psy-reel-logo {
      width: 50%;
      position: absolute;
      top: 100px;
      z-index: 8;
      animation: float 6s ease-in-out infinite;

      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .overlay-container {
      position: absolute;
      z-index: -10;
      width: 100%;
      height: 894px;
      top: 60px;

      background: linear-gradient(
        180deg,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0.55) 85.9%,
        rgba(0, 0, 23, 0.6) 90.46%,
        rgba(0, 0, 23, 0.85) 95.03%,
        #000017 100%
      );
    }

    .youtube-video-container {
      z-index: 100;
      position: absolute;
      top: 460px;

      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;

      width: 90%;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-content: space-around;
      gap: 60px 10px;

      iframe {
        width: 320px;
        height: 185px;
        border-radius: 5px;
      }
    }
  }
}

@media screen and (max-width: 425px) and (min-width: 320px) {
  .work-video-container {
    height: 894px;
    .work-header {
      position: absolute;
      z-index: 99;

      top: 80px;
      left: 5%;

      h2 {
        padding: 0;
        margin: 0;
        font-size: 1.6rem;
        color: white;
        font-family: "Roboto", sans-serif;
      }
    }

    .overlay-container {
      position: absolute;
      z-index: -10;
      width: 100%;
      height: 894px;
      top: 60px;

      background: linear-gradient(
        180deg,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0.55) 85.9%,
        rgba(0, 0, 23, 0.6) 90.46%,
        rgba(0, 0, 23, 0.85) 95.03%,
        #000017 100%
      );
    }

    .psy-reel-logo {
      width: 50%;
      position: absolute;
      top: 150px;
      z-index: 8;
      animation: float 6s ease-in-out infinite;

      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .youtube-video-container {
      z-index: 100;
      position: absolute;
      top: 380px;

      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;

      width: 50%;

      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-content: space-around;
      gap: 15px;

      iframe {
        width: 300px;
        height: 170px;
        border-radius: 5px;
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .work-video-container {
    height: 894px;
    .work-header {
      position: absolute;
      z-index: 99;

      top: 80px;
      left: 5%;

      h2 {
        padding: 0;
        margin: 0;
        font-size: 1.6rem;
        color: white;
        font-family: "Roboto", sans-serif;
      }
    }

    .overlay-container {
      position: absolute;
      z-index: -10;
      width: 100%;
      height: 894px;
      top: 60px;

      background: linear-gradient(
        180deg,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0.55) 85.9%,
        rgba(0, 0, 23, 0.6) 90.46%,
        rgba(0, 0, 23, 0.85) 95.03%,
        #000017 100%
      );
    }

    .psy-reel-logo {
      width: 50%;
      position: absolute;
      top: 150px;
      z-index: 8;
      animation: float 6s ease-in-out infinite;

      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .youtube-video-container {
      z-index: 100;
      position: absolute;
      top: 380px;

      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;

      width: 50%;

      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-content: space-around;
      gap: 15px;

      iframe {
        width: 260px;
        height: 150px;
        border-radius: 5px;
      }
    }
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}
