@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.services-video-container {
  width: 100%;
  height: 100vh;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  video {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -10;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .service-header {
    position: absolute;
    z-index: 99;

    top: 10%;
    left: 8%;

    h2 {
      padding: 0;
      margin: 0;
      font-size: 2.8rem;
      color: white;
      font-family: "Roboto", sans-serif;
    }
  }

  .overlay-container {
    position: absolute;
    z-index: -10;
    width: 100%;
    height: 100vh;
    top: 60px;

    background: linear-gradient(
      180deg,
      rgba(0, 0, 23, 0) 0.18%,
      rgba(0, 0, 23, 0) 0.18%,
      rgba(0, 0, 23, 0.55) 85.9%,
      rgba(0, 0, 23, 0.6) 90.46%,
      rgba(0, 0, 23, 0.85) 95.03%,
      #000017 100%
    );
  }

  .services-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 60px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: white;
    z-index: 22;
    overflow: hidden;

    .overlay {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }

    .service-main-content {
      width: 40%;
      position: absolute;
      right: 10%;
      padding: 25px 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 10;

      background: rgba(0, 0, 63, 0.33);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(10.1px);
      -webkit-backdrop-filter: blur(10.1px);

      .service-content-desc {
        font-family: "Roboto", sans-serif;
        font-size: 1.5rem;
        font-weight: 800;
        letter-spacing: 0.025em;
        line-height: 1.6rem;
      }

      .service-provide {
        width: 90%;

        .ads-film-campaign,
        .doc-film,
        .corporate-project {
          h3 {
            font-family: "Roboto", sans-serif;
            font-style: italic;
            font-size: 1.6rem;
            margin-bottom: 0.8rem;
            margin-top: 1.2rem;
            letter-spacing: 0.025em;
          }
          p {
            font-family: "Roboto", sans-serif;
            font-size: 1.3rem;
            font-weight: 400;
            margin-top: 0;
            letter-spacing: 0.025em;
            line-height: 1.7rem;
          }
        }
      }
    }

    .parallex-container {
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: -17%;
      display: flex;
      z-index: 2;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .service-logo {
      width: 30vw;
      position: absolute;
      left: 5%;
      top: 20%;
      z-index: 8;

      animation: float 6s ease-in-out infinite;

      z-index: 8;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

@media screen and (max-width: 1440px) and (min-width: 1024px) {
  .services-video-container {
    height: 900px;
    .overlay-container {
      position: absolute;
      z-index: -10;
      width: 100%;
      height: 900px;
      top: 60px;

      background: linear-gradient(
        180deg,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0.55) 85.9%,
        rgba(0, 0, 23, 0.6) 90.46%,
        rgba(0, 0, 23, 0.85) 95.03%,
        #000017 100%
      );
    }

    .service-header {
      position: absolute;
      z-index: 99;

      top: 100px;
      left: 5%;

      h2 {
        padding: 0;
        margin: 0;
        font-size: 2rem;
        color: white;
        font-family: "Roboto", sans-serif;
      }
    }

    .services-content {
      position: absolute;
      width: 100%;
      height: 900px;
      top: 60px;
      display: flex;
      align-items: center;
      color: white;
      z-index: -1;
      transition: ease;
      overflow: hidden;

      .service-logo {
        width: 30%;
        position: absolute;
        top: 25%;
        z-index: 8;

        animation: float 6s ease-in-out infinite;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .service-main-content {
        z-index: 10;
        position: absolute;
        right: 5%;
        width: 45%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 35px;
        margin: 0;

        background: rgba(0, 0, 63, 0.21);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);

        .service-content-desc {
          font-size: 1.2rem;
          font-weight: 500;
          letter-spacing: 0.025em;
          line-height: 1.6rem;
        }

        .service-provide {
          width: 95%;

          .ads-film-campaign,
          .doc-film,
          .corporate-project,
          .csr-doc,
          .audio-video-prod,
          .still-motion {
            h3 {
              font-size: 1.3rem;
              margin-bottom: 0.8rem;
              margin-top: 1rem;
            }
            p {
              font-size: 1rem;
              font-weight: 500;
              margin-top: 0;
              letter-spacing: 0.025em;
              line-height: 1.4rem;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .services-video-container {
    height: 900px;
    .service-header {
      position: absolute;
      z-index: 99;

      top: 10%;
      left: 5%;

      h2 {
        padding: 0;
        margin: 0;
        font-size: 2rem;
        color: white;
        font-family: "Roboto", sans-serif;
      }
    }

    .overlay-container {
      position: absolute;
      z-index: -10;
      width: 100%;
      height: 900px;
      top: 60px;

      background: linear-gradient(
        180deg,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0.55) 85.9%,
        rgba(0, 0, 23, 0.6) 90.46%,
        rgba(0, 0, 23, 0.85) 95.03%,
        #000017 100%
      );
    }

    .services-content {
      position: absolute;
      width: 100%;
      height: 900px;
      top: 60px;
      display: flex;
      align-items: center;
      color: white;
      z-index: -1;
      transition: ease;
      overflow: hidden;

      .service-logo {
        width: 30%;
        position: absolute;
        top: 30%;
        z-index: 8;

        animation: float 6s ease-in-out infinite;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .service-main-content {
        z-index: 10;
        position: absolute;
        right: 5%;
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 35px;
        margin: 0;

        background: rgba(0, 0, 63, 0.21);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);

        .service-content-desc {
          font-size: 1rem;
          font-weight: 500;
          letter-spacing: 0.025em;
          line-height: 1.5rem;
        }

        .service-provide {
          width: 95%;

          .ads-film-campaign,
          .doc-film,
          .corporate-project,
          .csr-doc,
          .audio-video-prod,
          .still-motion {
            h3 {
              font-size: 1.1rem;
              margin-bottom: 0.8rem;
              margin-top: 1rem;
            }
            p {
              font-size: 0.9rem;
              font-weight: 500;
              margin-top: 0;
              letter-spacing: 0.025em;
              line-height: 1.4rem;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) and (min-width: 425px) {
  .services-video-container {
    height: 894px;

    .service-header {
      position: absolute;
      z-index: 99;

      top: 80px;
      left: 5%;

      h2 {
        padding: 0;
        margin: 0;
        font-size: 1.6rem;
        color: white;
        font-family: "Roboto", sans-serif;
      }
    }

    .overlay-container {
      position: absolute;
      z-index: -10;
      width: 100%;
      height: 894px;
      top: 60px;

      background: linear-gradient(
        180deg,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0.55) 85.9%,
        rgba(0, 0, 23, 0.6) 90.46%,
        rgba(0, 0, 23, 0.85) 95.03%,
        #000017 100%
      );
    }

    .services-content {
      position: absolute;
      width: 100%;
      height: 894px;
      top: 60px;
      display: flex;
      align-items: center;
      color: white;
      z-index: -1;
      transition: ease;
      overflow: hidden;

      .service-logo {
        width: 40%;
        position: absolute;
        top: 5%;
        z-index: 8;

        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;

        animation: float 6s ease-in-out infinite;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .service-main-content {
        z-index: 100;
        position: absolute;
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 15px 25px;

        top: 360px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;

        background: rgba(0, 0, 63, 0.33);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10.1px);
        -webkit-backdrop-filter: blur(10.1px);

        .service-content-desc {
          font-size: 1rem;
          font-weight: 600;
          letter-spacing: 0.025em;
          line-height: 1.6rem;
        }

        .service-provide {
          width: 90%;

          .ads-film-campaign,
          .doc-film,
          .corporate-project,
          .csr-doc,
          .audio-video-prod,
          .still-motion {
            h3 {
              font-size: 1.1rem;
              margin-bottom: 0.8rem;
              margin-top: 1rem;
            }
            p {
              font-size: 0.9rem;
              font-weight: 400;
              margin-top: 0;
              letter-spacing: 0.025em;
              line-height: 1.2rem;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 425px) and (min-width: 320px) {
  .services-video-container {
    height: 894px;
    .service-header {
      position: absolute;
      z-index: 99;

      top: 80px;
      left: 5%;

      h2 {
        padding: 0;
        margin: 0;
        font-size: 1.6rem;
        color: white;
        font-family: "Roboto", sans-serif;
      }
    }

    .services-content {
      position: absolute;
      z-index: 50;
      width: 100%;
      height: 894px;
      top: 60px;
      display: flex;
      align-items: center;
      color: white;
      z-index: 200;
      transition: ease;
      overflow: hidden;

      .service-logo {
        width: 50%;
        position: absolute;
        //left: 25%;
        top: 10%;
        z-index: 8;

        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;

        animation: float 6s ease-in-out infinite;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .service-main-content {
        z-index: 110;
        position: absolute;
        width: 93%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 5px 10px;

        top: 340px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;

        background: rgba(0, 0, 63, 0.33);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10.1px);
        -webkit-backdrop-filter: blur(10.1px);

        .service-content-desc {
          font-size: 0.9rem;
          font-weight: 600;
          letter-spacing: 0.025em;
          line-height: 1.25rem;
          margin-bottom: 0;
          p {
            padding-bottom: 0;
            margin-bottom: 0;
          }
        }

        .service-provide {
          width: 100%;

          .ads-film-campaign,
          .doc-film,
          .corporate-project {
            h3 {
              font-size: 1rem;
              margin-bottom: 0.4rem;
              margin-top: 1.1rem;
            }
            p {
              font-size: 0.8rem;
              font-weight: 400;
              margin-top: 0;
              letter-spacing: 0.025em;
              line-height: 1.1rem;
            }
          }
        }
      }
    }

    .overlay-container {
      position: absolute;
      z-index: -10;
      width: 100%;
      // height: 100vh;
      height: 894px;
      top: 60px;

      // background: linear-gradient(
      //   180.21deg,
      //   rgba(0, 0, 23, 0) 0.18%,
      //   rgba(0, 0, 23, 0.6) 49.16%,
      //   rgba(0, 0, 23, 0.9) 81.81%,
      //   #000017 99.82%
      // );

      background: linear-gradient(
        180deg,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0.55) 85.9%,
        rgba(0, 0, 23, 0.6) 90.46%,
        rgba(0, 0, 23, 0.85) 95.03%,
        #000017 100%
      );
    }
  }
}

@media screen and (max-width: 320px) {
  .services-video-container {
    height: 894px;
    .service-header {
      position: absolute;
      z-index: 99;

      top: 10%;
      left: 5%;

      h2 {
        padding: 0;
        margin: 0;
        font-size: 1.6rem;
        color: white;
        font-family: "Roboto", sans-serif;
      }
    }

    .services-content {
      position: absolute;
      width: 100%;
      height: 894px;
      top: 60px;
      display: flex;
      align-items: center;
      color: white;
      z-index: 200;
      transition: ease;
      overflow: hidden;

      .service-logo {
        width: 60%;
        position: absolute;
        top: 10%;
        z-index: 8;

        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;

        animation: float 6s ease-in-out infinite;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .service-main-content {
        z-index: 110;
        position: absolute;
        width: 93%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 5px 10px;

        top: 35%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;

        background: rgba(0, 0, 63, 0.33);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10.1px);
        -webkit-backdrop-filter: blur(10.1px);

        .service-content-desc {
          font-size: 0.9rem;
          font-weight: 600;
          letter-spacing: 0.025em;
          line-height: 1.25rem;
          margin-bottom: 0;
          p {
            padding-bottom: 0;
            margin-bottom: 0;
          }
        }

        .service-provide {
          width: 100%;

          .ads-film-campaign,
          .doc-film,
          .corporate-project {
            h3 {
              font-size: 1rem;
              margin-bottom: 0.4rem;
              margin-top: 0.8rem;
            }
            p {
              font-size: 0.8rem;
              font-weight: 400;
              margin-top: 0;
              letter-spacing: 0.025em;
              line-height: 1.1rem;
            }
          }
        }
      }
    }

    .overlay-container {
      position: absolute;
      z-index: -10;
      width: 100%;
      height: 894px;
      top: 60px;

      background: linear-gradient(
        180deg,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0) 0.18%,
        rgba(0, 0, 23, 0.55) 85.9%,
        rgba(0, 0, 23, 0.6) 90.46%,
        rgba(0, 0, 23, 0.85) 95.03%,
        #000017 100%
      );
    }
  }
}
