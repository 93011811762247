.navbar-container {
  margin: 0;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #00003f;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.3px);
  -webkit-backdrop-filter: blur(10.3px);

  position: relative;
  z-index: 100000;

  .navbar-logo {
    width: auto;
    height: 80px;
    margin-left: 25px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .desktop-navbar {
    display: flex;
    height: 100%;
  }
  .mobile-navbar {
    display: flex;
  }
}

@media screen and (max-width: 660px) {
  .navbar-container {
    .navbar-logo {
      margin-left: 0;
    }
  }
}
